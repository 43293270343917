import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

const tableRows = [
  {
    product: 'Initial Physio consulatation',
    description: 'Initial consultation – 60mins',
    price: '$140',
  },
  {
    product: 'Initial Physio consulatation',
    description: 'Initial consultation – 45mins',
    price: '$130',
  },
  {
    product: 'Standard Consultation',
    description: 'Standard consultation – 30mins',
    price: '$100',
  },
  {
    product: 'Standard Consultation',
    description: 'Standard consultation – 45mins',
    price: '$120',
  },
];

const PhysioTable = () => {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Product/Service</TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="right">Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={`${row.product}-${row.description}`}>
            <TableCell component="th" scope="row">
              {row.product}
            </TableCell>
            <TableCell align="left">{row.description}</TableCell>
            <TableCell align="right">{row.price}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PhysioTable;
