import React from 'react';
import BackgroundSection from '../components/BackgroundSection';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Typography } from '@material-ui/core';
import PageSection from '../components/PageSection';
import TopBanner from '../components/TopBanner';
import BookNowButton from '../components/BookNowButton';
import Footer from '../components/Footer';
import TopBar from '../components/TopBar';
import { greys } from '../theme';
import PhysioTable from '../components/PhysioTable';

const useStyles = makeStyles((theme) => ({
  heading: {
    letterSpacing: 0,
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const ServicesPage = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      mainBackground: file(relativePath: { eq: "road-running.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      serviceBackground: file(relativePath: { eq: "overhead-weights.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 5192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <TopBar />
      <BackgroundSection
        backgroundPosition="center center"
        backgroundImage={data.mainBackground.childImageSharp.fluid}
      >
        <TopBanner heading="SERVICES" />
      </BackgroundSection>

      <PageSection
        id="physio"
        title="PHYSIO"
        bgColor={greys[0]}
        labelColor="black"
      >
        <Typography
          variant="h4"
          className={classes.heading}
          align="center"
          gutterBottom
        >
          What is Physio and what do Physio’s do?
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.text}
          gutterBottom
        >
          Physiotherapy is an evidence-based healthcare profession, which is
          continually challenged by the research. Physiotherapists are outcome
          focussed professionals, who can accurately identify a problem through
          a comprehensive assessment and then provide advice on strength and
          rehab programs to facilitate a goal directed outcomes.
        </Typography>
        <Typography
          variant="h4"
          className={classes.heading}
          align="center"
          gutterBottom
        >
          What sets Physios apart from other allied health professions?
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.text}
          gutterBottom
        >
          Physiotherapists are University-educated professionals, who have a
          large amount of exposure in the private and hospital sectors during
          our training. Through this exposure we see a large variety of
          conditions and are required to work alongside other medical
          professionals. We have a strong focus on Biomechanics and are trained
          to objectively assess your issue which will allow us to identify the
          problem. We are committed to providing treatment programs to ensure
          function is restored and a return to performance can be achieved
          through end to end management.
        </Typography>

        <Typography
          variant="h4"
          className={classes.heading}
          align="center"
          gutterBottom
        >
          Is a GP referral needed?
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.text}
          gutterBottom
        >
          No, you are not required to get a GP referral to see a Physio.
          However, for a Chronic Disease Management Program, you will need a GP
          referral for this service. Speak to your GP about your eligibility for
          this program.
        </Typography>

        <Typography
          variant="h4"
          className={classes.heading}
          align="center"
          gutterBottom
        >
          Can I claim Physiotherapy as an extra on my health insurance policy?{' '}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.text}
          gutterBottom
        >
          Physiotherapy is a standard optional extra on your private health
          insurance. Please check with your own provider and level cover as to
          your rebate options. This is dictated by your private health insurers,
          not by the clinic/clinician.
        </Typography>

        <Typography
          variant="h4"
          className={classes.heading}
          align="center"
          gutterBottom
        >
          Do you provide a compensable claim service (Workcover/TAC)?{' '}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.text}
          gutterBottom
        >
          Yes, we do. Please give our front desk team a call to chat through
          your situation and needs.
        </Typography>

        <Typography
          variant="h4"
          className={classes.heading}
          align="center"
          gutterBottom
        >
          Session prices
        </Typography>
        <PhysioTable />
      </PageSection>
      <BookNowButton />
      <Footer />
    </>
  );
};

export default ServicesPage;
